import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { ProductCode } from 'src/types/ProductCode';
import { TicketType } from 'src/types/TicketType';

const DECODER: D.Decoder<unknown, ProductCode> = D.literal(
  TicketType.SWISS_TRAVEL_PASS,
  TicketType.SWISS_TRAVEL_PASS_FLEX,
  TicketType.SWISS_HALF_FARE_CARD,

  TicketType.EXCURSION_JUNGFRAUJOCH,
  TicketType.EXCURSION_GORNERGRAT,
  TicketType.EXCURSION_MATTERHORN,
  TicketType.EXCURSION_PILATUS,
  TicketType.EXCURSION_SCHILTHORN_MURREN,
  TicketType.EXCURSION_SCHILTHORN_STECHELBERG,
  TicketType.EXCURSION_STANSERHORN,
  TicketType.EXCURSION_TITLIS,
  TicketType.EXCURSION_GLACIER,
  TicketType.EXCURSION_ROCHERS,

  TicketType.JUNGFRAUJOCH_CONNECTING_TICKET,

  TicketType.REGIONAL_OBERLAND_PASS,
  TicketType.REGIONAL_CENTRAL_PASS,
  TicketType.REGIONAL_JUNGFRAU_TRAVEL_PASS,
);

export const PRODUCT_CODE = C.make(DECODER, E.id());
