export const ROUTING_SITEMAP = {
  'basepath': {
    'de': '/de',
    'en': '/en',
    'es': '/es',
    'fr': '/fr',
    'it': '/it',
    'ja': '/ja',
    'ko': '/ko',
    'nl': '/nl',
    'zh-Hans': '/zh-hans',
    'zh-Hant': '/zh-hant',
    'pt': '/pt',
    'th': '/th',
  },

  'notfound': {
    'de': '/de/404',
    'en': '/en/404',
    'es': '/es/404',
    'fr': '/fr/404',
    'it': '/it/404',
    'ja': '/ja/404',
    'ko': '/ko/404',
    'nl': '/nl/404',
    'zh-Hans': '/zh-hans/404',
    'zh-Hant': '/zh-hant/404',
    'pt': '/pt/404',
    'th': '/th/404',
  },

  'homepage': {
    'de': '/de',
    'en': '/en',
    'es': '/es',
    'fr': '/fr',
    'it': '/it',
    'ja': '/ja',
    'ko': '/ko',
    'nl': '/nl',
    'zh-Hans': '/zh-hans',
    'zh-Hant': '/zh-hant',
    'pt': '/pt',
    'th': '/th',
  },
  'excursions': {
    'de': '/de/bergausfluege-tickets-kaufen',
    'en': '/en/buy-mountain-excursions-tickets',
    'es': '/es/comprar-billetes-excursiones-montana',
    'fr': '/fr/acheter-billets-excursions-montagne',
    'it': '/it/acquistare-biglietti-escursioni-montagna',
    'ja': '/ja/buy-mountain-excursions-tickets',
    'ko': '/ko/buy-mountain-excursions-tickets',
    'nl': '/nl/bergexcursie-tickets-kopen',
    'zh-Hans': '/zh-hans/buy-mountain-excursions-tickets',
    'zh-Hant': '/zh-hant/buy-mountain-excursions-tickets',
    'pt': '/pt/comprar-bilhetes-excursoes-montanha',
    'th': '/th/buy-mountain-excursions-tickets',
  },
  'regionalpasses': {
    'de': '/de/regionalpaesse-kaufen',
    'en': '/en/buy-regional-passes',
    'es': '/es/comprar-pases-regionales',
    'fr': '/fr/acheter-pass-regionaux',
    'it': '/it/acquistare-pass-regionali',
    'ja': '/ja/buy-regional-passes',
    'ko': '/ko/buy-regional-passes',
    'nl': '/nl/regionale-passen-kopen',
    'zh-Hans': '/zh-hans/buy-regional-passes',
    'zh-Hant': '/zh-hant/buy-regional-passes',
    'pt': '/pt/comprar-passes-regionais',
    'th': '/th/buy-regional-passes',
  },
  'faq': {
    'de': '/de/faq',
    'en': '/en/faq',
    'es': '/es/faq',
    'fr': '/fr/faq',
    'it': '/it/faq',
    'ja': '/ja/faq',
    'ko': '/ko/faq',
    'nl': '/nl/faq',
    'zh-Hans': '/zh-hans/faq',
    'zh-Hant': '/zh-hant/faq',
    'pt': '/pt/faq',
    'th': '/th/faq',
  },
  'guide': {
    'de': '/de/swiss-travel-pass-flex-anleitung',
    'en': '/en/swiss-travel-pass-flex-instructions',
    'es': '/es/instrucciones-swiss-travel-pass-flex',
    'fr': '/fr/guide-swiss-travel-pass-flex',
    'it': '/it/guida-swiss-travel-pass-flex',
    'ja': '/ja/swiss-travel-pass-flex-instructions',
    'ko': '/ko/swiss-travel-pass-flex-instructions',
    'nl': '/nl/swiss-travel-pass-flex-instructies',
    'zh-Hans': '/zh-hans/swiss-travel-pass-flex-instructions',
    'zh-Hant': '/zh-hant/swiss-travel-pass-flex-instructions',
    'pt': '/pt/instrucoes-swiss-travel-pass-flex',
    'th': '/th/swiss-travel-pass-flex-instructions',
  },
  'conditions': {
    'de': '/de/stornierungsbedingungen',
    'en': '/en/cancellation-policy',
    'es': '/es/condiciones-de-anulacion',
    'fr': '/fr/conditions-dannulation',
    'it': '/it/condizioni-di-annullamento',
    'ja': '/ja/cancellation-policy',
    'ko': '/ko/cancellation-policy',
    'nl': '/nl/annuleringsvoorwaarden',
    'zh-Hans': '/zh-hans/cancellation-policy',
    'zh-Hant': '/zh-hant/cancellation-policy',
    'pt': '/pt/politica-de-cancelamento',
    'th': '/th/cancellation-policy',
  },
  'legalnotice': {
    'de': '/de/impressum',
    'en': '/en/legal-notice',
    'es': '/es/informacion-legal',
    'fr': '/fr/mentions-legales',
    'it': '/it/colophon',
    'ja': '/ja/legal-notice',
    'ko': '/ko/legal-notice',
    'nl': '/nl/printen',
    'zh-Hans': '/zh-hans/legal-notice',
    'zh-Hant': '/zh-hant/legal-notice',
    'pt': '/pt/aviso-legal',
    'th': '/th/legal-notice',
  },
  'privacypolicy': {
    'de': '/de/datenschutzerklaerung',
    'en': '/en/privacy-policy',
    'es': '/es/politica-de-privacidad',
    'fr': '/fr/declaration-de-protection-des-donnees',
    'it': '/it/dichiarazione-sulla-protezione-dei-dati',
    'ja': '/ja/privacy-policy',
    'ko': '/ko/privacy-policy',
    'nl': '/nl/privacyverklaring',
    'zh-Hans': '/zh-hans/privacy-policy',
    'zh-Hant': '/zh-hant/privacy-policy',
    'pt': '/pt/politica-de-privacidade',
    'th': '/th/privacy-policy',
  },
  'about': {
    'de': '/de/ueber-uns',
    'en': '/en/about-us',
    'es': '/es/quienes-somos',
    'fr': '/fr/a-propos-de-nous',
    'it': '/it/chi-siamo',
    'ja': '/ja/about-us',
    'ko': '/ko/about-us',
    'nl': '/nl/over-ons',
    'zh-Hans': '/zh-hans/about-us',
    'zh-Hant': '/zh-hant/about-us',
    'pt': '/pt/sobre-nos',
    'th': '/th/about-us',
  },
  'gtc': {
    'de': '/de/agb',
    'en': '/en/gtc',
    'es': '/es/cg',
    'fr': '/fr/cg',
    'it': '/it/cg',
    'ja': '/ja/gtc',
    'ko': '/ko/gtc',
    'nl': '/nl/av',
    'zh-Hans': '/zh-hans/gtc',
    'zh-Hant': '/zh-hant/gtc',
    'pt': '/pt/ct',
    'th': '/th/gtc',
  },
  'contact': {
    'de': '/de/kontakt',
    'en': '/en/contact',
    'es': '/es/contacto',
    'fr': '/fr/contact',
    'it': '/it/contatto',
    'ja': '/ja/contact',
    'ko': '/ko/contact',
    'nl': '/nl/contact',
    'zh-Hans': '/zh-hans/contact',
    'zh-Hant': '/zh-hant/contact',
    'pt': '/pt/contacto',
    'th': '/th/contact',
  },
  'newsletter': {
    'de': '/de/newsletter',
    'en': '/en/newsletter',
    'es': '/es/newsletter',
    'fr': '/fr/newsletter',
    'it': '/it/newsletter',
    'ja': '/ja/newsletter',
    'ko': '/ko/newsletter',
    'nl': '/nl/newsletter',
    'zh-Hans': '/zh-hans/newsletter',
    'zh-Hant': '/zh-hant/newsletter',
    'pt': '/pt/newsletter',
    'th': '/th/newsletter',
  },

  'basket': {
    'de': '/de/warenkorb',
    'en': '/en/basket',
    'es': '/es/carrito',
    'fr': '/fr/panier',
    'it': '/it/carrello',
    'ja': '/ja/basket',
    'ko': '/ko/basket',
    'nl': '/nl/winkelmandje',
    'zh-Hans': '/zh-hans/basket',
    'zh-Hant': '/zh-hant/basket',
    'pt': '/pt/carrinho',
    'th': '/th/basket',
  },

  'checkout:base': {
    'de': '/de/checkout',
    'en': '/en/checkout',
    'es': '/es/checkout',
    'fr': '/fr/checkout',
    'it': '/it/checkout',
    'ja': '/ja/checkout',
    'ko': '/ko/checkout',
    'nl': '/nl/checkout',
    'zh-Hans': '/zh-hans/checkout',
    'zh-Hant': '/zh-hant/checkout',
    'pt': '/pt/checkout',
    'th': '/th/checkout',
  },
  'checkout:personal': {
    'de': '/de/checkout',
    'en': '/en/checkout',
    'es': '/es/checkout',
    'fr': '/fr/checkout',
    'it': '/it/checkout',
    'ja': '/ja/checkout',
    'ko': '/ko/checkout',
    'nl': '/nl/checkout',
    'zh-Hans': '/zh-hans/checkout',
    'zh-Hant': '/zh-hant/checkout',
    'pt': '/pt/checkout',
    'th': '/th/checkout',
  },
  'checkout:payment': {
    'de': '/de/checkout/payment',
    'en': '/en/checkout/payment',
    'es': '/es/checkout/payment',
    'fr': '/fr/checkout/payment',
    'it': '/it/checkout/payment',
    'ja': '/ja/checkout/payment',
    'ko': '/ko/checkout/payment',
    'nl': '/nl/checkout/payment',
    'zh-Hans': '/zh-hans/checkout/payment',
    'zh-Hant': '/zh-hant/checkout/payment',
    'pt': '/pt/checkout/payment',
    'th': '/th/checkout/payment',
  },

  'payment:base': {
    'de': '/de/payment',
    'en': '/en/payment',
    'es': '/es/payment',
    'fr': '/fr/payment',
    'it': '/it/payment',
    'ja': '/ja/payment',
    'ko': '/ko/payment',
    'nl': '/nl/payment',
    'zh-Hans': '/zh-hans/payment',
    'zh-Hant': '/zh-hant/payment',
    'pt': '/pt/payment',
    'th': '/th/payment',
  },
  'payment:cancel': {
    'de': '/de/payment/cancel',
    'en': '/en/payment/cancel',
    'es': '/es/payment/cancel',
    'fr': '/fr/payment/cancel',
    'it': '/it/payment/cancel',
    'ja': '/ja/payment/cancel',
    'ko': '/ko/payment/cancel',
    'nl': '/nl/payment/cancel',
    'zh-Hans': '/zh-hans/payment/cancel',
    'zh-Hant': '/zh-hant/payment/cancel',
    'pt': '/pt/payment/cancel',
    'th': '/th/payment/cancel',
  },
  'payment:failure': {
    'de': '/de/payment/failure',
    'en': '/en/payment/failure',
    'es': '/es/payment/failure',
    'fr': '/fr/payment/failure',
    'it': '/it/payment/failure',
    'ja': '/ja/payment/failure',
    'ko': '/ko/payment/failure',
    'nl': '/nl/payment/failure',
    'zh-Hans': '/zh-hans/payment/failure',
    'zh-Hant': '/zh-hant/payment/failure',
    'pt': '/pt/payment/failure',
    'th': '/th/payment/failure',
  },
  'payment:success': {
    'de': '/de/payment/success',
    'en': '/en/payment/success',
    'es': '/es/payment/success',
    'fr': '/fr/payment/success',
    'it': '/it/payment/success',
    'ja': '/ja/payment/success',
    'ko': '/ko/payment/success',
    'nl': '/nl/payment/success',
    'zh-Hans': '/zh-hans/payment/success',
    'zh-Hant': '/zh-hant/payment/success',
    'pt': '/pt/payment/success',
    'th': '/th/payment/success',
  },

  'p2p-ticket': {
    'de': '/de/bahntickets-schweiz-kaufen',
    'en': '/en/buy-swiss-train-tickets',
    'es': '/es/comprar-billetes-de-tren',
    'fr': '/fr/acheter-billets-de-train-suisse',
    'it': '/it/comprare-biglietti-del-treno',
    'ja': '/ja/buy-swiss-train-tickets',
    'ko': '/ko/buy-swiss-train-tickets',
    'nl': '/nl/treinkaartjes-zwitserland-kopen',
    'zh-Hans': '/zh-hans/buy-swiss-train-tickets',
    'zh-Hant': '/zh-hant/buy-swiss-train-tickets',
    'pt': '/pt/comprar-bilhetes-de-trem-suicos',
    'th': '/th/buy-swiss-train-tickets',
  },

  'product:base': {
    'de': '/de',
    'en': '/en',
    'es': '/es',
    'fr': '/fr',
    'it': '/it',
    'ja': '/ja',
    'ko': '/ko',
    'nl': '/nl',
    'zh-Hans': '/zh-hans',
    'zh-Hant': '/zh-hant',
    'pt': '/pt',
    'th': '/th',
  },
  'product:swissPass': {
    'de': '/de/swiss-travel-pass-kaufen',
    'en': '/en/buy-swiss-travel-pass',
    'es': '/es/comprar-swiss-travel-pass',
    'fr': '/fr/acheter-swiss-travel-pass',
    'it': '/it/acquistare-swiss-travel-pass',
    'ja': '/ja/buy-swiss-travel-pass',
    'ko': '/ko/buy-swiss-travel-pass',
    'nl': '/nl/swiss-travel-pass-kopen',
    'zh-Hans': '/zh-hans/buy-swiss-travel-pass',
    'zh-Hant': '/zh-hant/buy-swiss-travel-pass',
    'pt': '/pt/comprar-swiss-travel-pass',
    'th': '/th/buy-swiss-travel-pass',
  },
  'product:swissFlexiPass': {
    'de': '/de/swiss-travel-pass-flex-kaufen',
    'en': '/en/buy-swiss-travel-pass-flex',
    'es': '/es/comprar-swiss-travel-pass-flex',
    'fr': '/fr/acheter-swiss-travel-pass-flex',
    'it': '/it/acquistare-swiss-travel-pass-flex',
    'ja': '/ja/buy-swiss-travel-pass-flex',
    'ko': '/ko/buy-swiss-travel-pass-flex',
    'nl': '/nl/swiss-travel-pass-flex-kopen',
    'zh-Hans': '/zh-hans/buy-swiss-travel-pass-flex',
    'zh-Hant': '/zh-hant/buy-swiss-travel-pass-flex',
    'pt': '/pt/comprar-swiss-travel-pass-flex',
    'th': '/th/buy-swiss-travel-pass-flex',
  },
  'product:swissHalfFareCard': {
    'de': '/de/swiss-half-fare-card-kaufen',
    'en': '/en/buy-swiss-half-fare-card',
    'es': '/es/comprar-swiss-half-fare-card',
    'fr': '/fr/acheter-swiss-half-fare-card',
    'it': '/it/acquistare-swiss-half-fare-card',
    'ja': '/ja/buy-swiss-half-fare-card',
    'ko': '/ko/buy-swiss-half-fare-card',
    'nl': '/nl/swiss-half-fare-card-kopen',
    'zh-Hans': '/zh-hans/buy-swiss-half-fare-card',
    'zh-Hant': '/zh-hant/buy-swiss-half-fare-card',
    'pt': '/pt/comprar-swiss-half-fare-card',
    'th': '/th/buy-swiss-half-fare-card',
  },

  'product:excursionJungfraujoch': {
    'de': '/de/jungfraujoch-ticket-kaufen',
    'en': '/en/buy-jungfraujoch-ticket',
    'es': '/es/comprar-billete-jungfraujoch',
    'fr': '/fr/acheter-billet-jungfraujoch',
    'it': '/it/acquistare-biglietto-jungfraujoch',
    'ja': '/ja/buy-jungfraujoch-ticket',
    'ko': '/ko/buy-jungfraujoch-ticket',
    'nl': '/nl/jungfraujoch-ticket-kopen',
    'zh-Hans': '/zh-hans/buy-jungfraujoch-ticket',
    'zh-Hant': '/zh-hant/buy-jungfraujoch-ticket',
    'pt': '/pt/comprar-bilhete-jungfraujoch',
    'th': '/th/buy-jungfraujoch-ticket',
  },
  'product:excursionGornergrat': {
    'de': '/de/gornergrat-ticket-kaufen',
    'en': '/en/buy-gornergrat-ticket',
    'es': '/es/comprar-billete-gornergrat',
    'fr': '/fr/acheter-billet-gornergrat',
    'it': '/it/acquistare-biglietto-gornergrat',
    'ja': '/ja/buy-gornergrat-ticket',
    'ko': '/ko/buy-gornergrat-ticket',
    'nl': '/nl/ticket-gornergrat-kopen',
    'zh-Hans': '/zh-hans/buy-gornergrat-ticket',
    'zh-Hant': '/zh-hant/buy-gornergrat-ticket',
    'pt': '/pt/comprar-bilhete-gornergrat',
    'th': '/th/buy-gornergrat-ticket',
  },
  'product:excursionMatterhornParadise': {
    'de': '/de/matterhorn-glacier-paradise-tickets-kaufen',
    'en': '/en/buy-matterhorn-glacier-paradise-tickets',
    'es': '/es/comprar-billete-matterhorn-glacier-paradise',
    'fr': '/fr/acheter-billets-matterhorn-glacier-paradise',
    'it': '/it/acquista-biglietti-matterhorn-glacier-paradise',
    'ja': '/ja/buy-matterhorn-glacier-paradise-tickets',
    'ko': '/ko/buy-matterhorn-glacier-paradise-tickets',
    'nl': '/nl/tickets-matterhorn-glacier-paradise-kopen',
    'zh-Hans': '/zh-hans/buy-matterhorn-glacier-paradise-tickets',
    'zh-Hant': '/zh-hant/buy-matterhorn-glacier-paradise-tickets',
    'pt': '/pt/comprar-bilhetes-matterhorn-glacier-paradise',
    'th': '/th/buy-matterhorn-glacier-paradise-tickets',
  },
  'product:excursionPilatus': {
    'de': '/de/pilatus-ticket-kaufen',
    'en': '/en/buy-pilatus-ticket',
    'es': '/es/comprar-billete-pilatus',
    'fr': '/fr/acheter-billet-pilate',
    'it': '/it/acquistare-biglietto-pilatus',
    'ja': '/ja/buy-pilatus-ticket',
    'ko': '/ko/buy-pilatus-ticket',
    'nl': '/nl/ticket-pilatus-kopen',
    'zh-Hans': '/zh-hans/buy-pilatus-ticket',
    'zh-Hant': '/zh-hant/buy-pilatus-ticket',
    'pt': '/pt/comprar-bilhete-pilatus',
    'th': '/th/buy-pilatus-ticket',
  },
  'product:excursionSchilthornMurren': {
    'de': '/de/muerren-nach-schilthorn-ticket-kaufen',
    'en': '/en/buy-muerren-to-schilthorn-ticket',
    'es': '/es/comprar-billete-muerren-schilthorn',
    'fr': '/fr/acheter-billet-muerren-schilthorn',
    'it': '/it/acquistare-biglietto-muerren-a-schilthorn',
    'ja': '/ja/buy-muerren-to-schilthorn-ticket',
    'ko': '/ko/buy-muerren-to-schilthorn-ticket',
    'nl': '/nl/ticket-muerren-naar-schilthorn-kopen',
    'zh-Hans': '/zh-hans/buy-muerren-to-schilthorn-ticket',
    'zh-Hant': '/zh-hant/buy-muerren-to-schilthorn-ticket',
    'pt': '/pt/comprar-bilhete-muerren-schilthorn',
    'th': '/th/buy-muerren-to-schilthorn-ticket',
  },
  'product:excursionSchilthornFromStechelberg': {
    'de': '/de/stechelberg-nach-schilthorn-ticket-kaufen',
    'en': '/en/buy-stechelberg-to-schilthorn-ticket',
    'es': '/es/comprar-billete-stechelberg-schilthorn',
    'fr': '/fr/acheter-billet-stechelberg-schilthorn',
    'it': '/it/acquistare-biglietto-stechelberg-a-schilthorn',
    'ja': '/ja/buy-stechelberg-to-schilthorn-ticket',
    'ko': '/ko/buy-stechelberg-to-schilthorn-ticket',
    'nl': '/nl/ticket-stechelberg-naar-schilthorn-kopen',
    'zh-Hans': '/zh-hans/buy-stechelberg-to-schilthorn-ticket',
    'zh-Hant': '/zh-hant/buy-stechelberg-to-schilthorn-ticket',
    'pt': '/pt/comprar-bilhete-stechelberg-schilthorn',
    'th': '/th/buy-stechelberg-to-schilthorn-ticket',
  },
  'product:excursionStanserhorn': {
    'de': '/de/cabrio-stanserhorn-ticket-kaufen',
    'en': '/en/buy-cabrio-stanserhorn-ticket',
    'es': '/es/comprar-billete-cabrio-stanserhorn',
    'fr': '/fr/acheter-billet-cabrio-stanserhorn',
    'it': '/it/acquistare-biglietto-cabrio-stanserhorn',
    'ja': '/ja/buy-cabrio-stanserhorn-ticket',
    'ko': '/ko/buy-cabrio-stanserhorn-ticket',
    'nl': '/nl/ticket-cabrio-stanserhorn-kopen',
    'zh-Hans': '/zh-hans/buy-cabrio-stanserhorn-ticket',
    'zh-Hant': '/zh-hant/buy-cabrio-stanserhorn-ticket',
    'pt': '/pt/comprar-bilhete-cabrio-stanserhorn',
    'th': '/th/buy-cabrio-stanserhorn-ticket',
  },
  'product:excursionTitlis': {
    'de': '/de/titlis-ticket-kaufen',
    'en': '/en/buy-titlis-ticket',
    'es': '/es/comprar-billete-titlis',
    'fr': '/fr/acheter-billet-titlis',
    'it': '/it/acquistare-biglietto-titlis',
    'ja': '/ja/buy-titlis-ticket',
    'ko': '/ko/buy-titlis-ticket',
    'nl': '/nl/ticket-titlis-kopen',
    'zh-Hans': '/zh-hans/buy-titlis-ticket',
    'zh-Hant': '/zh-hant/buy-titlis-ticket',
    'pt': '/pt/comprar-bilhete-titlis',
    'th': '/th/buy-titlis-ticket',
  },
  'product:excursionGlacier': {
    'de': '/de/glacier-3000-ticket-kaufen',
    'en': '/en/buy-glacier-3000-ticket',
    'es': '/es/comprar-billete-glacier-3000',
    'fr': '/fr/acheter-billet-glacier-3000',
    'it': '/it/acquistare-biglietto-glacier-3000',
    'ja': '/ja/buy-glacier-3000-ticket',
    'ko': '/ko/buy-glacier-3000-ticket',
    'nl': '/nl/ticket-glacier-3000-kopen',
    'zh-Hans': '/zh-hans/buy-glacier-3000-ticket',
    'zh-Hant': '/zh-hant/buy-glacier-3000-ticket',
    'pt': '/pt/comprar-bilhete-glacier-3000',
    'th': '/th/buy-glacier-3000-ticket',
  },
  'product:excursionRochersDeNaye': {
    'de': '/de/rochers-de-naye-tickets-kaufen',
    'en': '/en/buy-rochers-de-naye-tickets',
    'es': '/es/comprar-billete-rochers-de-naye',
    'fr': '/fr/acheter-billets-rochers-de-naye',
    'it': '/it/acquistare-biglietti-rochers-de-naye',
    'ja': '/ja/buy-rochers-de-naye-tickets',
    'ko': '/ko/buy-rochers-de-naye-tickets',
    'nl': '/nl/tickets-rochers-de-naye-kopen',
    'zh-Hans': '/zh-hans/buy-rochers-de-naye-tickets',
    'zh-Hant': '/zh-hant/buy-rochers-de-naye-tickets',
    'pt': '/pt/comprar-bilhetes-rochers-de-naye',
    'th': '/th/buy-rochers-de-naye-tickets',
  },

  'product:jungfraujochConnectingTicket': {
    'de': '/de/jungfraujoch-anschlussticket-kaufen',
    'en': '/en/buy-jungfraujoch-connecting-ticket',
    'es': '/es/comprar-billete-conexion-jungfraujoch',
    'fr': '/fr/acheter-billet-connexion-jungfraujoch',
    'it': '/it/acquistare-biglietto-collegamento-jungfraujoch',
    'ja': '/ja/buy-jungfraujoch-connecting-ticket',
    'ko': '/ko/buy-jungfraujoch-connecting-ticket',
    'nl': '/nl/jungfraujoch-aansluitticket-kopen',
    'zh-Hans': '/zh-hans/buy-jungfraujoch-connecting-ticket',
    'zh-Hant': '/zh-hant/buy-jungfraujoch-connecting-ticket',
    'pt': '/pt/comprar-bilhete-conexao-jungfraujoch',
    'th': '/th/buy-jungfraujoch-connecting-ticket',
  },

  'product:regionalBerneseOberlandPass': {
    'de': '/de/berner-oberland-pass-kaufen',
    'en': '/en/buy-berner-oberland-pass',
    'es': '/es/comprar-berner-oberland-pass',
    'fr': '/fr/acheter-pass-oberland-bernois',
    'it': '/it/acquistare-pass-delloberland-bernese',
    'ja': '/ja/buy-berner-oberland-pass',
    'ko': '/ko/buy-berner-oberland-pass',
    'nl': '/nl/berner-oberland-pass-kopen',
    'zh-Hans': '/zh-hans/buy-berner-oberland-pass',
    'zh-Hant': '/zh-hant/buy-berner-oberland-pass',
    'pt': '/pt/comprar-berner-oberland-pass',
    'th': '/th/buy-berner-oberland-pass',
  },
  'product:regionalCentralSwissPass': {
    'de': '/de/tell-pass-kaufen',
    'en': '/en/buy-tell-pass',
    'es': '/es/comprar-tell-pass',
    'fr': '/fr/acheter-tell-pass',
    'it': '/it/acquistare-tell-pass',
    'ja': '/ja/buy-tell-pass',
    'ko': '/ko/buy-tell-pass',
    'nl': '/nl/tell-pass-kopen',
    'zh-Hans': '/zh-hans/buy-tell-pass',
    'zh-Hant': '/zh-hant/buy-tell-pass',
    'pt': '/pt/comprar-tell-pass',
    'th': '/th/buy-tell-pass',
  },
  'product:regionalJungfrauTravelPass': {
    'de': '/de/jungfrau-travel-pass-kaufen',
    'en': '/en/buy-jungfrau-travel-pass',
    'es': '/es/comprar-jungfrau-travel-pass',
    'fr': '/fr/acheter-jungfrau-travel-pass',
    'it': '/it/acquistare-jungfrau-travel-pass',
    'ja': '/ja/buy-jungfrau-travel-pass',
    'ko': '/ko/buy-jungfrau-travel-pass',
    'nl': '/nl/jungfrau-travel-pass-kopen',
    'zh-Hans': '/zh-hans/buy-jungfrau-travel-pass',
    'zh-Hant': '/zh-hant/buy-jungfrau-travel-pass',
    'pt': '/pt/comprar-jungfrau-travel-pass',
    'th': '/th/buy-jungfrau-travel-pass',
  },
};
