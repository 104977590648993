import React, { useCallback } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Row } from 'src/components/common/Grid';
import { PaymentTypes } from 'src/components/layout/PaymentTypes';
import { PARTNER_LINKS, PROVEN_EXPERT_LINKS } from 'src/constants/links';
import { DEFAULT_LOGO } from 'src/constants/logo';
import { DEFAULT_LOGO_TITLE_ONDARK } from 'src/constants/logo-title-ondark';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { getOneTrustReady } from 'src/selectors/getOneTrustReady';

export function PageFooterDefault(): React.ReactElement {
  const language = useSelector(getCurrentLanguage);
  const affiliate = useSelector(getAffiliateData);
  const showCookieSettings = useSelector(getOneTrustReady);
  const showProvenExpert = affiliate.appThemeSettings.provenExpertSeal ?? false;

  const toggleCookieSettings = useCallback(() => {
    if (window.OneTrust) {
      window.OneTrust.ToggleInfoDisplay();
    }
  }, []);

  return (
    <div className="sts-ui-page-footer">
      <Row className="sts-ui-page-footer__content">
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__column">
          <div className="sts-ui-page-footer__title">
            <FormattedMessage id="Layout.Footer.ContactsTitle"/>
          </div>

          <ul className="sts-ui-page-footer__contact-list">
            <li className="sts-ui-page-footer__contact-item">
              <RouteLink route="contact" className="sts-ui-page-footer__contact-link">
                <FormattedMessage id="Layout.Footer.ContactsLink"/>
              </RouteLink>
            </li>
            <li className="sts-ui-page-footer__contact-item">
              <RouteLink route="faq" className="sts-ui-page-footer__contact-link">
                <FormattedMessage id="Layout.Footer.FaqLink"/>
              </RouteLink>
            </li>
            {showProvenExpert && (
              <li className="sts-ui-page-footer__contact-item">
                <a
                  href={PROVEN_EXPERT_LINKS[language]}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Customer reviews"
                  className="sts-ui-page-footer__contact-link"
                >
                  <FormattedMessage id="Layout.Footer.CustomerReviews"/>
                </a>
              </li>
            )}
          </ul>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__column">
          <div className="sts-ui-page-footer__title">
            <FormattedMessage id="Layout.Footer.PaymentTypes"/>
          </div>
          <div className="sts-ui-page-footer__payment">
            <PaymentTypes/>
          </div>
          <div className="sts-ui-page-footer__newsletter">
            <RouteLink route="newsletter" className="sts-ui-primary-button sts-ui-page-footer__newsletter-link">
              <FormattedMessage id="Layout.Footer.NewsletterLink"/>
            </RouteLink>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__column">
          <div className="sts-ui-page-footer__title">
            <FormattedMessage id="Layout.OurPartners"/>
          </div>
          <ul className="sts-ui-page-footer__partner-list">
            <li className="sts-ui-page-footer__partner-item">
              <a
                href={PARTNER_LINKS[language].hotellerieSuisse.link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Hotellerie Suisse"
                className="sts-ui-page-footer__partner-link"
              >
                <img {...PARTNER_LINKS[language].hotellerieSuisse.logo} alt="Hotellerie Suisse"/>
              </a>
            </li>
            <li className="sts-ui-page-footer__partner-item">
              <a
                href={PARTNER_LINKS[language].sbb.link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Sbb"
                className="sts-ui-page-footer__partner-link"
              >
                <img {...PARTNER_LINKS[language].sbb.logo} alt="Sbb" width="149"/>
              </a>
            </li>
            <li className="sts-ui-page-footer__partner-item">
              <a
                href={PARTNER_LINKS[language].swissTourism.link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Swiss Tourism"
                className="sts-ui-page-footer__partner-link"
              >
                <img {...PARTNER_LINKS[language].swissTourism.logo} alt="Swiss Tourism" width="130" height="40"/>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="sts-ui-page-footer__bottom">
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__logo">
          <a href="https://switzerlandtravelcentre.com" target="_self">
            <div className="sts-ui-page-footer__logo-wrapper">
              <img className="sts-ui-page-footer__logo--sign" src={DEFAULT_LOGO} alt="Logo"/>
              <img className="sts-ui-page-footer__logo--title" src={DEFAULT_LOGO_TITLE_ONDARK} alt="Logo"/>
            </div>
          </a>
          <FormattedHTMLMessage
            id="Layout.FooterText"
            values={{ currentYear: new Date().getFullYear() }}
          />
        </Col>
        <Col xs={12} sm="auto" md="auto" lg="auto" className="sts-ui-page-footer__affiliate">
          Affiliate ID {affiliate.affiliateId}
        </Col>
        <Col xs={12} sm="auto" md="auto" lg="auto" className="sts-ui-page-footer__navbar">
          <ul className="sts-ui-page-footer__navbar-list">
            {showCookieSettings && (
              <li className="sts-ui-page-footer__navbar-item">
                <button
                  type="button"
                  onClick={toggleCookieSettings}
                  className="sts-ui-page-footer__navbar-link"
                >
                  <FormattedMessage id="Layout.CookieSettings"/>
                </button>
              </li>
            )}
            <li className="sts-ui-page-footer__navbar-item">
              <RouteLink route="about" className="sts-ui-page-footer__navbar-link">
                <FormattedMessage id="Layout.AboutUs"/>
              </RouteLink>
            </li>
            <li className="sts-ui-page-footer__navbar-item">
              <RouteLink route="privacypolicy" className="sts-ui-page-footer__navbar-link">
                <FormattedMessage id="Layout.PrivacyPolicy"/>
              </RouteLink>
            </li>
            <li className="sts-ui-page-footer__navbar-item">
              <RouteLink route="gtc" className="sts-ui-page-footer__navbar-link">
                <FormattedMessage id="Layout.GTC"/>
              </RouteLink>
            </li>
            <li className="sts-ui-page-footer__navbar-item">
              <RouteLink route="legalnotice" className="sts-ui-page-footer__navbar-link">
                <FormattedMessage id="Layout.Impressum"/>
              </RouteLink>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
