import React from 'react';
import { useSelector } from 'react-redux';
import { SEARCH_PERFORM } from 'src/actions/SearchActions';
import { HomepageScreen } from 'src/components/homepage/HomepageScreen';
import { useAction } from 'src/hooks/useAction';
import { getBasketHasHalfFareCard } from 'src/selectors/getBasketHasHalfFareCard';
import { getHomepageData } from 'src/selectors/getHomepageData';
import { getIsHomepageBannerShown } from 'src/selectors/getIsHomepageBannerShown';
import { getIsP2pOfferShown } from 'src/selectors/getIsP2pOfferShown';
import { getPromotionService } from 'src/selectors/getPromotionService';

export function HomepagePage(): React.ReactElement {
  const products = useSelector(getHomepageData);
  const isBannerShown = useSelector(getIsHomepageBannerShown);
  const isHalfFareCardBooked = useSelector(getBasketHasHalfFareCard);
  const promotionService = useSelector(getPromotionService);
  const searchProduct = useAction(SEARCH_PERFORM.trigger);
  const isP2pOfferShown = useSelector(getIsP2pOfferShown);

  return (
    <HomepageScreen
      products={products}
      isBannerShown={isBannerShown}
      isHalfFareCardBooked={isHalfFareCardBooked}
      promotionService={promotionService}
      onSearchProduct={searchProduct}
      isP2pOfferShown={isP2pOfferShown}
    />
  );
}
