import './BasketScreen.scss';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { BasketP2pTicketView } from 'src/components/basket/BasketP2pTicketView';
import { BasketProductView } from 'src/components/basket/BasketProductView';
import { BasketUpgradeView } from 'src/components/basket/BasketUpgradeView';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { P2pTicketBanner } from 'src/components/common/P2pTicketBanner';
import { Typography } from 'src/components/common/Typography';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { ProductGridView } from 'src/components/product-list/ProductGridView';
import { ProductSecondaryCard } from 'src/components/product-list/ProductSecondaryCard';
import { FIX_PRICE_PRODUCTS } from 'src/constants/fixPriceProducts';
import { TICKETS_BY_GROUPS } from 'src/constants/tickets';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { BasketProductListItem } from 'src/types/BasketProductListItem';
import { BasketUpsellGroup } from 'src/types/BasketUpsellGroup';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { TicketGroup } from 'src/types/TicketGroup';
import { TicketType } from 'src/types/TicketType';

type Props = {
  readonly currency: Currency;

  readonly products: ReadonlyArray<BasketProductListItem>;
  readonly upsells: ReadonlyArray<BasketUpsellGroup>;

  readonly removing: BasketProductListItem | null;
  readonly upgrading: ProductCode | null;

  readonly onRemove: (product: BasketProductListItem) => void;
  readonly onUpgrade: (code: ProductCode) => void;

  readonly isP2pOfferShown: boolean;
};

export class BasketScreen extends React.Component<Props> {
  public render(): React.ReactElement {
    const { products, isP2pOfferShown } = this.props;
    const basketValid = products.length > 0 && products.every((it) => !it.invalid);

    return (
      <div className="sts-ui-basket-screen">
        <div className="sts-ui-basket-screen__page-title">
          <Typography variant="page-title" as="h1">
            <FormattedMessage id="Basket.Your"/>
          </Typography>
        </div>

        {products.length > 0
          ? this.renderProducts()
          : null}

        {basketValid
          ? this.renderTotalPrice()
          : null}

        {basketValid
          ? this.renderActions()
          : null}

        {basketValid && isP2pOfferShown
          ? this.renderP2pPromoBanner()
          : null}

        {basketValid
          ? this.renderUpsells()
          : null}

        {products.length === 0
          ? this.renderEmpty()
          : null}
      </div>
    );
  }

  private renderProducts(): React.ReactNode {
    const { products, onRemove, removing, currency } = this.props;

    return products.map((product) => (
      <div key={product.uuid} className="sts-ui-basket-screen__product">
        {product.type === 'product' && (
          <BasketProductView
            product={product}
            onRemove={onRemove}
            removing={product.uuid === removing?.uuid}
          />
        )}
        {product.type === 'p2p-ticket' && (
          <BasketP2pTicketView
            currency={currency}
            product={product}
            onRemove={onRemove}
            removing={product.uuid === removing?.uuid}
          />
        )}
      </div>
    ));
  }

  private renderTotalPrice(): React.ReactNode {
    return (
      <div className="sts-ui-basket-screen__price">
        <div className="sts-ui-basket-screen__price-label">
          <FormattedMessage id="Basket.TotalPrice"/>
        </div>
        <div className="sts-ui-basket-screen__price-value">
          <FormattedPrice value={this.getTotalPrice()}/>
        </div>
      </div>
    );
  }

  private renderP2pPromoBanner(): React.ReactNode {
    const { products } = this.props;

    const hideBanner = products.some((it) => it.type === 'product' && HIDE_P2P_BANNER_WITH.has(it.code));
    if (hideBanner) {
      return null;
    }

    const showBanner = products.some((it) => it.type === 'product' && SHOW_P2P_BANNER_WITH.has(it.code));
    if (!showBanner) {
      return null;
    }

    const hasHalfFareCard = products.some((it) => it.type === 'product' && it.code === TicketType.SWISS_HALF_FARE_CARD);

    return (
      <div className="sts-ui-basket-screen__promo-banner">
        <P2pTicketBanner>
          {hasHalfFareCard
            ? <FormattedHTMLMessage id="Basket.P2P.Banner.HalfFareCardInBasket"/>
            : <FormattedHTMLMessage id="Basket.P2P.Banner.NoHalfFareCardInBasket"/>}
        </P2pTicketBanner>
      </div>
    );
  }

  private renderActions(): React.ReactNode {
    return (
      <div className="sts-ui-basket-screen__actions">
        <RouteLink
          route="homepage"
          className="sts-ui-ghost-button sts-ui-basket-screen__btn-homepage"
        >
          <FormattedMessage id="Basket.ContinueShopping"/>
          <ArrowRightIcon/>
        </RouteLink>

        <RouteLink
          route="checkout:personal"
          className="sts-ui-primary-button sts-ui-basket-screen__btn-checkout"
        >
          <FormattedMessage id="Basket.ProceedToPayment"/>
          <ArrowRightIcon/>
        </RouteLink>
      </div>
    );
  }

  private renderUpsells(): React.ReactNode {
    const { upsells, onUpgrade, upgrading } = this.props;

    return upsells
      .filter((group) => group.products.length > 0)
      .map((group) => (
        <div key={group.type} className="sts-ui-basket-screen__upsell-group">
          {group.type === 'related' && (
            <div className="sts-ui-basket-screen__block-title">
              <Typography variant="block-title" as="h2">
                <FormattedMessage id="Basket.Upsells.YouMayAlsoLike"/>
              </Typography>
            </div>
          )}
          {group.type === 'excursion' && (
            <div className="sts-ui-basket-screen__block-title">
              <Typography variant="block-title" as="h2">
                <FormattedMessage id="Basket.Upsells.BookMountainExcursions"/>
              </Typography>
            </div>
          )}

          <div className="sts-ui-basket-screen__upsell-grid">
            <ProductGridView>
              {group.products.map((upsell) => (
                <React.Fragment key={`${upsell.type}-${upsell.product.code}`}>
                  {upsell.type === 'upgrade' && (
                    <BasketUpgradeView
                      product={upsell.product}
                      onUpgrade={onUpgrade}
                      upgrading={upsell.product.code === upgrading}
                    />
                  )}
                  {upsell.type === 'product' && (
                    <ProductSecondaryCard
                      code={upsell.product.code}
                      price={upsell.product.price}
                      fixPrice={FIX_PRICE_PRODUCTS.includes(upsell.product.code)}
                    />
                  )}
                </React.Fragment>
              ))}
            </ProductGridView>
          </div>
        </div>
      ));
  }

  private renderEmpty(): React.ReactElement {
    return (
      <div className="sts-ui-basket-screen__empty">
        <h4 className="sts-ui-basket-screen__empty-title">
          <FormattedMessage id="Basket.Empty"/>
        </h4>

        <p className="sts-ui-basket-screen__empty-text">
          <FormattedMessage id="Basket.Empty.GotoSearchMessage"/>
          <br/>
          <RouteLink route="homepage" anchor="#search">
            <FormattedMessage id="Basket.Empty.GotoSearchButton"/>
            &nbsp;
            <ArrowRightIcon/>
          </RouteLink>
        </p>

        <p className="sts-ui-basket-screen__empty-text">
          <FormattedMessage id="Basket.Empty.GotoOverviewMessage"/>
          <br/>
          <RouteLink route="homepage">
            <FormattedMessage id="Homepage.OverviewAllTickets"/>
            &nbsp;
            <ArrowRightIcon/>
          </RouteLink>
        </p>
      </div>
    );
  }

  private getTotalPrice(): Decimal {
    const { products } = this.props;
    return products.reduce((sum, it) => sum.add(it.totalPrice), new Decimal(0));
  }
}

const HIDE_P2P_BANNER_WITH: ReadonlySet<ProductCode> = new Set([
  TicketType.SWISS_TRAVEL_PASS,
  TicketType.SWISS_TRAVEL_PASS_FLEX,
]);
const SHOW_P2P_BANNER_WITH: ReadonlySet<ProductCode> = new Set([
  TicketType.SWISS_HALF_FARE_CARD,
  ...TICKETS_BY_GROUPS[TicketGroup.MOUNTAIN_EXCURSION],
  ...TICKETS_BY_GROUPS[TicketGroup.REGIONAL_PASSES],
]);
