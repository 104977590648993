import './ProductFormView.scss';
import { useForm } from '@form-ts/react';
import { Either } from 'fp-ts/Either';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { ProductFormViewAgreement } from 'src/components/product/ProductFormViewAgreement';
import { ProductFormViewDiscount } from 'src/components/product/ProductFormViewDiscount';
import { ProductFormViewPriceList } from 'src/components/product/ProductFormViewPriceList';
import { ProductFormViewTraveler } from 'src/components/product/ProductFormViewTraveler';
import { ProductFormViewTripDate } from 'src/components/product/ProductFormViewTripDate';
import { ProductFormViewValidity } from 'src/components/product/ProductFormViewValidity';
import { FormError } from 'src/forms/types/FormError';
import { Integer } from 'src/types/Integer';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { ProductPriceErrorCode } from 'src/types/ProductPriceErrorCode';
import { ProductPriceList } from 'src/types/ProductPriceList';
import { TicketFields } from 'src/types/TicketFields';
import { TicketPricePeriod } from 'src/types/TicketPricePeriod';
import { TicketValidity } from 'src/types/TicketValidity';

type Props = {
  readonly type: ProductCode;
  readonly fields: TicketFields;
  readonly dates: ReadonlyArray<TicketPricePeriod>;
  readonly validity: TicketValidity;
  readonly ticketPrice: Either<ProductPriceErrorCode, ProductPriceList>;

  readonly showFamilyCardOption: boolean;
  readonly bookedFamilyCardCount: Integer;

  readonly invalid: boolean;
  readonly loading: boolean;
  readonly formData: ProductFormData;

  readonly inBasket: boolean;
  readonly isUndeliverable: boolean;

  readonly onChange: (formData: ProductFormData) => void;
  readonly onSubmit: (formData: ProductFormData) => void;
};

export function ProductFormView({
  type,
  fields,
  dates,
  validity,
  ticketPrice,
  showFamilyCardOption,
  bookedFamilyCardCount,
  invalid,
  loading,
  formData,
  inBasket,
  isUndeliverable,
  onChange,
  onSubmit,
}: Props): React.ReactElement {
  const form = useForm<ProductFormData, FormError>(`product.${type}`, {
    reinitialize: true,
    initialValues: formData,
  });

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;
    if (prevState.values !== nextState.values) {
      onChange(nextState.values);
    }
  }), [form, onChange]);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const nextState = form.currentState;
    onSubmit(nextState.values);
  }, [form, onSubmit]);

  return (
    <form className="sts-ui-product-form-view" onSubmit={handleSubmit}>
      <div className="sts-ui-product-form-view__group">
        <ProductFormViewTripDate
          form={form}
          fields={fields}
          dates={dates}
        />
      </div>
      <div className="sts-ui-product-form-view__group">
        <ProductFormViewValidity
          type={type}
          form={form}
          fields={fields}
          validity={validity}
        />
      </div>
      <div className="sts-ui-product-form-view__group">
        <ProductFormViewTraveler
          type={type}
          form={form}
          fields={fields}
          showFamilyCardOption={showFamilyCardOption}
          showFamilyCardDiscount={bookedFamilyCardCount > 0}
        />
      </div>
      <div className="sts-ui-product-form-view__group">
        <ProductFormViewDiscount
          type={type}
          form={form}
          fields={fields}
          validity={validity}
          showFamilyCardDiscount={bookedFamilyCardCount > 0}
        />
      </div>
      <div className="sts-ui-product-form-view__price-list">
        <ProductFormViewPriceList
          type={type}
          ticketPrice={ticketPrice}
          bookedFamilyCardCount={bookedFamilyCardCount}
        />
      </div>
      <div className="sts-ui-product-form-view__agreement">
        <ProductFormViewAgreement
          type={type}
          form={form}
          fields={fields}
        />
      </div>
      <div className="sts-ui-product-form-view__actions">
        <button
          type="submit"
          className="sts-ui-primary-button"
          disabled={isUndeliverable || invalid}
        >
          {inBasket
            ? <FormattedMessage id="Overview.Tickets.Change"/>
            : <FormattedMessage id="Form.Product.Book"/>}
          {loading
            ? <LoadingIcon/>
            : <ArrowRightIcon/>}
        </button>
      </div>
    </form>
  );
}
