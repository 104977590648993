import { createSelector } from 'reselect';
import { TICKETS_BY_GROUPS } from 'src/constants/tickets';
import { getBasketData } from 'src/selectors/getBasketData';
import { getBasketUpgradeList } from 'src/selectors/getBasketUpgradeList';
import { BasketUpsellGroup } from 'src/types/BasketUpsellGroup';
import { ProductListItem } from 'src/types/ProductListItem';
import { TicketGroup } from 'src/types/TicketGroup';
import { TicketType } from 'src/types/TicketType';
import { isDefined } from 'src/utils/guard';

export const getBasketUpsellGroups = createSelector([
  getBasketData,
  getBasketUpgradeList,
], (data, upgrades): ReadonlyArray<BasketUpsellGroup> => {
  if (data.products.length === 0) {
    return [];
  }

  const firstAddedProduct = data.products.reduce((a, b) => (a.position < b.position ? a : b));

  if (firstAddedProduct.code === TicketType.REGIONAL_JUNGFRAU_TRAVEL_PASS) {
    const excludeJungfraujochExcursion = (it: ProductListItem): boolean => {
      return it.code !== TicketType.EXCURSION_JUNGFRAUJOCH;
    };

    return [
      {
        type: 'related',
        products: [TicketType.JUNGFRAUJOCH_CONNECTING_TICKET]
          .map((code) => data.upsells.find((it) => it.code === code))
          .filter(isDefined)
          .map((it) => ({ type: 'product', product: it })),
      },
      {
        type: 'excursion',
        products: TICKETS_BY_GROUPS[TicketGroup.MOUNTAIN_EXCURSION]
          .map((code) => data.upsells.find((it) => it.code === code))
          .filter(isDefined)
          .filter(excludeJungfraujochExcursion)
          .map((it) => ({ type: 'product', product: it })),
      },
    ];
  }
  if (
    TICKETS_BY_GROUPS[TicketGroup.PUBLIC_TRANSPORT_PASSES].includes(firstAddedProduct.code) ||
    TICKETS_BY_GROUPS[TicketGroup.REGIONAL_PASSES].includes(firstAddedProduct.code)
  ) {
    return [
      {
        type: 'related',
        products: upgrades.map((it) => ({ type: 'upgrade', product: it })),
      },
      {
        type: 'excursion',
        products: TICKETS_BY_GROUPS[TicketGroup.MOUNTAIN_EXCURSION]
          .map((code) => data.upsells.find((it) => it.code === code))
          .filter(isDefined)
          .map((it) => ({ type: 'product', product: it })),
      },
    ];
  }
  if (TICKETS_BY_GROUPS[TicketGroup.MOUNTAIN_EXCURSION].includes(firstAddedProduct.code)) {
    return [
      {
        type: 'related',
        products: TICKETS_BY_GROUPS[TicketGroup.PUBLIC_TRANSPORT_PASSES]
          .map((code) => data.upsells.find((it) => it.code === code))
          .filter(isDefined)
          .map((it) => ({ type: 'product', product: it })),
      },
      {
        type: 'excursion',
        products: TICKETS_BY_GROUPS[TicketGroup.MOUNTAIN_EXCURSION]
          .map((code) => data.upsells.find((it) => it.code === code))
          .filter(isDefined)
          .map((it) => ({ type: 'product', product: it })),
      },
    ];
  }

  return [];
});
