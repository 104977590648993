import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BASKET_REMOVE, BASKET_UPGRADE } from 'src/actions/BasketActions';
import { BasketScreen } from 'src/components/basket/BasketScreen';
import { useAction } from 'src/hooks/useAction';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getBasketProductList } from 'src/selectors/getBasketProductList';
import { getBasketRemoving } from 'src/selectors/getBasketRemoving';
import { getBasketUpgrading } from 'src/selectors/getBasketUpgrading';
import { getBasketUpsellGroups } from 'src/selectors/getBasketUpsellGroups';
import { getIsP2pOfferShown } from 'src/selectors/getIsP2pOfferShown';
import { BasketProductListItem } from 'src/types/BasketProductListItem';
import { ProductCode } from 'src/types/ProductCode';

export function BasketPage(): React.ReactElement {
  const currency = useSelector(getCurrentCurrency);

  const products = useSelector(getBasketProductList);
  const upsells = useSelector(getBasketUpsellGroups);

  const removing = useSelector(getBasketRemoving);
  const upgrading = useSelector(getBasketUpgrading);

  const removeProduct = useAction(BASKET_REMOVE.request);
  const onProductRemove = useCallback((product: BasketProductListItem) => {
    removeProduct(undefined, product);
  }, [removeProduct]);

  const upgradeProduct = useAction(BASKET_UPGRADE.request);
  const onProductUpgrade = useCallback((code: ProductCode) => {
    upgradeProduct(undefined, code);
  }, [upgradeProduct]);

  const isP2pOfferShown = useSelector(getIsP2pOfferShown);

  return (
    <BasketScreen
      currency={currency}
      products={products}
      upsells={upsells}
      removing={removing}
      upgrading={upgrading}
      onRemove={onProductRemove}
      onUpgrade={onProductUpgrade}
      isP2pOfferShown={isP2pOfferShown}
    />
  );
}
