import './ProductFormViewAgreement.scss';
import { FormController } from '@form-ts/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxField } from 'src/forms/fields/CheckboxField';
import { FormError } from 'src/forms/types/FormError';
import { defaultIfNull } from 'src/forms/utils/transform';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketFields } from 'src/types/TicketFields';
import { TicketType } from 'src/types/TicketType';

type Props = {
  readonly type: ProductCode;
  readonly form: FormController<ProductFormData, FormError>;
  readonly fields: TicketFields;
};

export function ProductFormViewAgreement({ type, form, fields }: Props): React.ReactElement | null {
  if (!fields.displayFields.agreement) {
    return null;
  }

  return (
    <div className="sts-ui-product-form-view-agreement">
      <CheckboxField
        field={form.field.at('agreement').transform(defaultIfNull(false))}
        label={getAgreementLabel(type)}
      />
    </div>
  );
}

function getAgreementLabel(type: ProductCode): ReactElement {
  if (type === TicketType.JUNGFRAUJOCH_CONNECTING_TICKET) {
    return <FormattedMessage id="Form.Product.IConfirmJungfrauTravelPassPossession"/>;
  }
  return <FormattedMessage id="Form.Product.IConfirmNotUseBySwissCitizen"/>;
}
