import './ContactScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { HubSpotForm } from 'src/components/common/HubSpotForm';
import { Typography } from 'src/components/common/Typography';
import { Language } from 'src/types/Language';

type Props = {
  readonly language: Language;
};

export function ContactScreen({ language }: Props): React.ReactElement {
  return (
    <div className="sts-ui-contact-screen">
      <div className="sts-ui-contact-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="Contact.Page.Title"/>
        </Typography>
      </div>
      <div className="sts-ui-contact-screen__page-subtitle">
        <Typography variant="page-subtitle" as="div">
          <FormattedHTMLMessage id="Contact.Page.Description"/>
        </Typography>
      </div>

      <div className="sts-ui-contact-screen__content">
        <HubSpotForm
          key={language}
          region="na1"
          portalId="2657592"
          formId={FORM_ID[language]}
        />
      </div>
    </div>
  );
}

const FORM_ID: Record<Language, string> = {
  'de': 'b30fb141-a903-49da-99e2-ab56504119b3',
  'en': '0e3ffdd7-40a7-4db1-bfcd-3acd1d806548',
  'es': '2f73ffed-b4cd-48f2-8d6a-9c4b7291ac3e',
  'fr': 'cbf46731-efa1-45b1-86a6-dfad47510baf',
  'it': 'c785fffd-0ca2-44af-a350-f0e319c8a9b0',
  'ja': '66033025-2af0-47ba-9bbe-286217cf267b',
  'ko': '1fb6fed9-5e67-47b3-a61c-80eabb9ca7bc',
  'nl': 'ff13bcfd-1102-49c0-839d-0716a03767e7',
  'zh-Hans': '5161a268-33a3-46a6-9edd-f6c4c21b0283',
  'zh-Hant': 'f5589d92-b077-4eda-9712-f9eead94e019',
  'pt': 'fc3fdf37-b252-421e-811d-bbed43d5125d',
  'th': ' 5319c821-b38c-4438-b7b7-84ba1abd868f',
};
