import React from 'react';
import { useSelector } from 'react-redux';
import { RegionalPassesScreen } from 'src/components/regionalPasses/RegionalPassesScreen';
import { getBasketHasHalfFareCard } from 'src/selectors/getBasketHasHalfFareCard';
import { getIsP2pOfferShown } from 'src/selectors/getIsP2pOfferShown';
import { getRegionalPassesProducts } from 'src/selectors/getRegionalPassesProducts';

export function RegionalPassesPage(): React.ReactElement {
  const products = useSelector(getRegionalPassesProducts);
  const isHalfFareCardBooked = useSelector(getBasketHasHalfFareCard);
  const isP2pOfferShown = useSelector(getIsP2pOfferShown);

  return (
    <RegionalPassesScreen
      products={products}
      isHalfFareCardBooked={isHalfFareCardBooked}
      isP2pOfferShown={isP2pOfferShown}
    />
  );
}
