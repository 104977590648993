import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { Language } from 'src/types/Language';

export const FORMAT_TIME = 'HH:mm';

export const FORMAT_DATE_SHORT = 'dd.MM.yyyy';

export const FORMAT_DATE_MIDDLE: ReadonlyRecord<Language, string> = {
  'en': 'dd MMM yy',
  'it': 'dd MMM yy',
  'es': 'dd MMM yy',
  'fr': 'dd MMM yy',
  'nl': 'dd MMM yy',
  'de': "dd'.' MMM yy",
  'ja': 'dd MMM yy',
  'ko': 'dd MMM yy',
  'zh-Hans': 'dd/MM/yy',
  'zh-Hant': 'dd/MM/yy',
  'pt': 'dd MMM yy',
  'th': 'dd MMM yy',
};

export const FORMAT_DATE_LONG: ReadonlyRecord<Language, string> = {
  'en': 'dd MMMM yyyy',
  'it': 'dd MMMM yyyy',
  'es': 'dd MMMM yyyy',
  'fr': 'dd MMMM yyyy',
  'nl': 'dd MMMM yyyy',
  'de': "dd'.' MMMM yyyy",
  'ja': 'dd MMMM yyyy',
  'ko': 'dd MMMM yyyy',
  'zh-Hans': 'dd/MM/yy',
  'zh-Hant': 'dd/MM/yy',
  'pt': 'dd MMMM yyyy',
  'th': 'dd MMMM yyyy',
};
