/* eslint-disable import/order */
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { ImageAttributes } from 'src/types/ImageAttributes';
import { Language } from 'src/types/Language';
import { Season } from 'src/types/Season';

import HOMEPAGE_BG_w_500 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_500.webp';
import HOMEPAGE_BG_w_665 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_665.webp';
import HOMEPAGE_BG_w_860 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_860.webp';
import HOMEPAGE_BG_w_985 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_985.webp';
import HOMEPAGE_BG_w_1098 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1098.webp';
import HOMEPAGE_BG_w_1207 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1207.webp';
import HOMEPAGE_BG_w_1250 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1250.webp';
import HOMEPAGE_BG_w_1336 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1336.webp';
import HOMEPAGE_BG_w_1419 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1419.webp';
import HOMEPAGE_BG_w_1513 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1513.webp';
import HOMEPAGE_BG_w_1596 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1596.webp';
import HOMEPAGE_BG_w_1667 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1667.webp';
import HOMEPAGE_BG_w_1732 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1732.webp';
import HOMEPAGE_BG_w_1802 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1802.webp';
import HOMEPAGE_BG_w_1874 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1874.webp';
import HOMEPAGE_BG_w_1938 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1938.webp';
import HOMEPAGE_BG_w_1997 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_1997.webp';
import HOMEPAGE_BG_w_2000 from 'src/assets/img/homepage/head_jcr2cb_c_scale,w_2000.webp';

import HOMEPAGE_BANNER_SRC from 'src/assets/img/homepage/banner.jpg';

import VALIDITY_MAP_DE_1X from 'src/assets/img/validity-map/Area_of_Validity_Swiss_Travel_Pass_2024_DE_1x.jpg';
import VALIDITY_MAP_DE_2X from 'src/assets/img/validity-map/Area_of_Validity_Swiss_Travel_Pass_2024_DE_2x.jpg';
import VALIDITY_MAP_EN_1X from 'src/assets/img/validity-map/Area_of_Validity_Swiss_Travel_Pass_2024_EN_1x.jpg';
import VALIDITY_MAP_EN_2X from 'src/assets/img/validity-map/Area_of_Validity_Swiss_Travel_Pass_2024_EN_2x.jpg';

// FIXME: the same images
import BASKET_UPGRADE_SUMMER from 'src/assets/img/summer_upgrade.png';
import BASKET_UPGRADE_WINTER from 'src/assets/img/winter_upgrade.png';

export const HOMEPAGE_BACKGROUND: ImageAttributes = {
  src: HOMEPAGE_BG_w_2000,
  sizes: '(max-width: 2000px) 100vw, 2000px',
  srcSet: [
    `${HOMEPAGE_BG_w_500} 500w`,
    `${HOMEPAGE_BG_w_665} 665w`,
    `${HOMEPAGE_BG_w_860} 860w`,
    `${HOMEPAGE_BG_w_985} 985w`,
    `${HOMEPAGE_BG_w_1098} 1098w`,
    `${HOMEPAGE_BG_w_1207} 1207w`,
    `${HOMEPAGE_BG_w_1250} 1250w`,
    `${HOMEPAGE_BG_w_1336} 1336w`,
    `${HOMEPAGE_BG_w_1419} 1419w`,
    `${HOMEPAGE_BG_w_1513} 1513w`,
    `${HOMEPAGE_BG_w_1596} 1596w`,
    `${HOMEPAGE_BG_w_1667} 1667w`,
    `${HOMEPAGE_BG_w_1732} 1732w`,
    `${HOMEPAGE_BG_w_1802} 1802w`,
    `${HOMEPAGE_BG_w_1874} 1874w`,
    `${HOMEPAGE_BG_w_1938} 1938w`,
    `${HOMEPAGE_BG_w_1997} 1997w`,
    `${HOMEPAGE_BG_w_2000} 2000w`,
  ].join(','),
};

export const HOMEPAGE_BANNER: ImageAttributes = {
  src: HOMEPAGE_BANNER_SRC,
};

export const BASKET_UPGRADE_IMAGES: ReadonlyRecord<Season, string> = {
  [Season.SUMMER]: BASKET_UPGRADE_SUMMER,
  [Season.WINTER]: BASKET_UPGRADE_WINTER,
};

const VALIDITY_MAP_DE_IMG: ImageAttributes = {
  src: VALIDITY_MAP_DE_1X,
  srcSet: `${VALIDITY_MAP_DE_1X} 1x, ${VALIDITY_MAP_DE_2X} 2x`,
};

const VALIDITY_MAP_EN_IMG: ImageAttributes = {
  src: VALIDITY_MAP_EN_1X,
  srcSet: `${VALIDITY_MAP_EN_1X} 1x, ${VALIDITY_MAP_EN_2X} 2x`,
};

export const VALIDITY_MAPS: ReadonlyRecord<Language, ImageAttributes> = {
  'en': VALIDITY_MAP_EN_IMG,
  'de': VALIDITY_MAP_DE_IMG,
  'it': VALIDITY_MAP_EN_IMG,
  'fr': VALIDITY_MAP_EN_IMG,
  'es': VALIDITY_MAP_EN_IMG,
  'nl': VALIDITY_MAP_EN_IMG,
  'ja': VALIDITY_MAP_EN_IMG,
  'ko': VALIDITY_MAP_EN_IMG,
  'zh-Hans': VALIDITY_MAP_EN_IMG,
  'zh-Hant': VALIDITY_MAP_EN_IMG,
  'pt': VALIDITY_MAP_EN_IMG,
  'th': VALIDITY_MAP_EN_IMG,
};
