import { pipe } from 'fp-ts/function';
import * as N from 'fp-ts/number';
import * as O from 'fp-ts/Ord';
import { Ord } from 'fp-ts/Ord';
import { BasketProductOrder } from 'src/types/BasketProductOrder';
import { ProductCode } from 'src/types/ProductCode';
import { TicketType } from 'src/types/TicketType';

export const STRICT_ORD: Ord<unknown> = {
  // @ts-expect-error UNKNOWN should not be used with comparion operators
  compare: (a, b) => (a < b ? -1 : a > b ? 1 : 0),
  equals: (a, b) => a === b,
};

export const PRODUCT_CODE_ORD: Ord<ProductCode> = {
  compare: (a, b) => {
    const diff = PRODUCT_CODE_POSITION[a] - PRODUCT_CODE_POSITION[b];
    return diff < 0 ? -1 : diff > 0 ? 1 : 0;
  },
  equals: (a, b) => {
    const diff = PRODUCT_CODE_POSITION[a] - PRODUCT_CODE_POSITION[b];
    return diff === 0;
  },
};

const BASKET_PRODUCT_BY_POSITION: O.Ord<BasketProductOrder> = pipe(
  N.Ord,
  O.contramap((product) => product.position),
);
const BASKET_PRODUCT_BY_CODE: O.Ord<BasketProductOrder> = pipe(
  PRODUCT_CODE_ORD,
  O.contramap((product) => product.code),
);
export const BASKET_PRODUCT_ORD = O.getSemigroup<BasketProductOrder>().concat(
  BASKET_PRODUCT_BY_CODE,
  BASKET_PRODUCT_BY_POSITION,
);

const PRODUCT_CODE_POSITION: Readonly<Record<ProductCode, number>> = {
  [TicketType.SWISS_TRAVEL_PASS]: 1,
  [TicketType.SWISS_TRAVEL_PASS_FLEX]: 1,
  [TicketType.SWISS_HALF_FARE_CARD]: 1,

  [TicketType.EXCURSION_GORNERGRAT]: 2,
  [TicketType.EXCURSION_MATTERHORN]: 2,
  [TicketType.EXCURSION_PILATUS]: 2,
  [TicketType.EXCURSION_ROCHERS]: 2,
  [TicketType.EXCURSION_SCHILTHORN_MURREN]: 2,
  [TicketType.EXCURSION_SCHILTHORN_STECHELBERG]: 2,
  [TicketType.EXCURSION_STANSERHORN]: 2,
  [TicketType.EXCURSION_TITLIS]: 2,
  [TicketType.EXCURSION_JUNGFRAUJOCH]: 2,
  [TicketType.EXCURSION_GLACIER]: 2,

  [TicketType.JUNGFRAUJOCH_CONNECTING_TICKET]: 2,

  [TicketType.REGIONAL_OBERLAND_PASS]: 2,
  [TicketType.REGIONAL_CENTRAL_PASS]: 2,
  [TicketType.REGIONAL_JUNGFRAU_TRAVEL_PASS]: 2,
};
