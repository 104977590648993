import './RegionalPassesScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { P2pTicketBanner } from 'src/components/common/P2pTicketBanner';
import { Typography } from 'src/components/common/Typography';
import { ProductGridView } from 'src/components/product-list/ProductGridView';
import { ProductSecondaryCard } from 'src/components/product-list/ProductSecondaryCard';
import { FIX_PRICE_PRODUCTS } from 'src/constants/fixPriceProducts';
import { ProductListItem } from 'src/types/ProductListItem';

type Props = {
  readonly products: ReadonlyArray<ProductListItem>;
  readonly isHalfFareCardBooked: boolean;
  readonly isP2pOfferShown: boolean;
};

export function RegionalPassesScreen({ products, isHalfFareCardBooked, isP2pOfferShown }: Props): React.ReactElement {
  const group1 = products.slice(0, 2);
  const group2 = products.slice(2);

  return (
    <div className="sts-ui-regional-passes-screen">
      <div className="sts-ui-regional-passes-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="RegionalPasses.Page.Title"/>
        </Typography>
      </div>
      <div className="sts-ui-regional-passes-screen__page-subtitle">
        <Typography variant="page-subtitle" as="p">
          <FormattedHTMLMessage id="RegionalPasses.Page.Description"/>
        </Typography>
      </div>

      <div className="sts-ui-regional-passes-screen__content">
        {group1.length > 0 && (
          <ProductGridView>
            {group1.map((product) => (
              <ProductSecondaryCard
                key={product.code}
                code={product.code}
                price={product.price}
                fixPrice={FIX_PRICE_PRODUCTS.includes(product.code)}
              />
            ))}
          </ProductGridView>
        )}

        {isP2pOfferShown && (
          <div className="sts-ui-regional-passes-screen__promo-banner">
            <P2pTicketBanner>
              {isHalfFareCardBooked
                ? <FormattedMessage id="RegionalPasses.P2P.Banner.HalfFareCardInBasket"/>
                : <FormattedMessage id="RegionalPasses.P2P.Banner.NoHalfFareCardInBasket"/>}
            </P2pTicketBanner>
          </div>
        )}

        {group2.length > 0 && (
          <ProductGridView>
            {group2.map((product) => (
              <ProductSecondaryCard
                key={product.code}
                code={product.code}
                price={product.price}
                fixPrice={FIX_PRICE_PRODUCTS.includes(product.code)}
              />
            ))}
          </ProductGridView>
        )}
      </div>
    </div>
  );
}
