export const EMPTY_ELEMENTS: ReadonlySet<string> = new Set([
  'br',
  'hr',
]);

export const BASIC_ELEMENTS: ReadonlySet<string> = new Set([
  'dl',
  'dt',
  'dd',

  'ol',
  'ul',
  'li',

  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',

  'div',
  'span',

  'abbr',
  'code',
  'cite',
  'blockquote',

  'u',
  'i',
  's',
  'b',

  'em',
  'del',
  'small',
  'strong',

  'sub',
  'sup',

  'table',
  'tbody',
  'thead',
  'tfoot',
  'tr',
  'td',
  'th',
]);

export const VALID_STYLE_PROPS: ReadonlySet<string> = new Set([
  'top',
  'bottom',
  'left',
  'right',

  'color',

  'float',
  'clear',

  'font-family',
  'font-size',
  'font-style',
  'font-weight',

  'list-style',
  'list-style-type',

  'line-break',
  'line-height',

  'object-fit',
  'object-position',

  'opacity',

  'text-wrap',
  'white-space',
  'white-space-collapse',

  'text-align',
  'vertical-align',

  'text-decoration',
  'text-decoration-color',
  'text-decoration-line',
  'text-decoration-style',
  'text-decoration-thickness',
]);
