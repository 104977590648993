export const PRODUCTION = process.env.NODE_ENV === 'production';

export const HOST_API = process.env.HOST_API || 'http://localhost:8000';
export const HOST_CANONICAL = process.env.HOST_CANONICAL || window.origin;

export const ENABLE_GTM_TRACKING = process.env.ENABLE_GTM_TRACKING === '1';
export const OVERRIDE_GTM_TRACKING_ID = process.env.OVERRIDE_GTM_TRACKING_ID || '';

export const SENTRY_LOG = process.env.SENTRY_LOG === '1';
export const SENTRY_DSN = process.env.SENTRY_DSN || '';
export const SENTRY_ENV = process.env.SENTRY_ENV || 'dev';
export const SENTRY_RELEASE = process.env.SENTRY_RELEASE || 'no';

export const ONETRUST_DOMAIN_ID = process.env.ONETRUST_DOMAIN_ID || 'ec58f9de-aa04-446f-9117-caaf3d7b5e69-test';
export const DEFAULT_AFFILIATE_ID = process.env.DEFAULT_AFFILIATE_ID || '189';
