import { RouteName } from 'src/routing/types/RouteName';

export const ROUTING_LEGACY_URL_MAP: Readonly<Record<string, RouteName>> = {
  'home': 'homepage',

  'basket': 'basket',

  'checkout': 'checkout:personal',
  'checkout/personal': 'checkout:personal',
  'checkout/payment': 'checkout:payment',

  'payment/cancel': 'payment:cancel',
  'payment/success': 'payment:success',
  'payment/failure': 'payment:failure',

  'product/swissPass': 'product:swissPass',
  'product/swissFlexiPass': 'product:swissFlexiPass',
  'product/swissHalfFareCard': 'product:swissHalfFareCard',

  'product/excursionJungfraujoch': 'product:excursionJungfraujoch',
  'product/excursionGornergrat': 'product:excursionGornergrat',
  'product/excursionMatterhornParadise': 'product:excursionMatterhornParadise',
  'product/excursionPilatus': 'product:excursionPilatus',
  'product/excursionSchilthornMurren': 'product:excursionSchilthornMurren',
  'product/excursionSchilthornFromStechelberg': 'product:excursionSchilthornFromStechelberg',
  'product/excursionStanserhorn': 'product:excursionStanserhorn',
  'product/excursionTitlis': 'product:excursionTitlis',
  'product/excursionGlacier': 'product:excursionGlacier',
  'product/excursionRochersDeNaye': 'product:excursionRochersDeNaye',

  'product/jungfraujochConnectingTicket': 'product:jungfraujochConnectingTicket',

  'product/regionalBerneseOberlandPass': 'product:regionalBerneseOberlandPass',
  'product/regionalCentralSwissPass': 'product:regionalCentralSwissPass',
  'product/regionalJungfrauTravelPass': 'product:regionalJungfrauTravelPass',
};
