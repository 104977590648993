import React from 'react';
import { FormattedMessage } from 'react-intl';

export const LANGUAGE_LIST = [
  {
    title: <FormattedMessage id="Menu.Language.English"/>,
    value: 'en',
  },
  {
    title: <FormattedMessage id="Menu.Language.Italian"/>,
    value: 'it',
  },
  {
    title: <FormattedMessage id="Menu.Language.French"/>,
    value: 'fr',
  },
  {
    title: <FormattedMessage id="Menu.Language.Dutch"/>,
    value: 'nl',
  },
  {
    title: <FormattedMessage id="Menu.Language.German"/>,
    value: 'de',
  },
  {
    title: <FormattedMessage id="Menu.Language.Spanish"/>,
    value: 'es',
  },
  {
    title: <FormattedMessage id="Menu.Language.Japan"/>,
    value: 'ja',
  },
  {
    title: <FormattedMessage id="Menu.Language.Korean"/>,
    value: 'ko',
  },
  {
    title: <FormattedMessage id="Menu.Language.Chinese-Simplified"/>,
    value: 'zh-Hans',
  },
  {
    title: <FormattedMessage id="Menu.Language.Chinese-Traditional"/>,
    value: 'zh-Hant',
  },
  {
    title: <FormattedMessage id="Menu.Language.Portuguese"/>,
    value: 'pt',
  },
  {
    title: <FormattedMessage id="Menu.Language.Thai"/>,
    value: 'th',
  },
] as const;
