import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { ProductCode } from 'src/types/ProductCode';
import { TicketGroup } from 'src/types/TicketGroup';
import { TicketType } from 'src/types/TicketType';

export const HOMEPAGE_PRODUCTS: ReadonlyRecord<TicketGroup, ReadonlyArray<ProductCode>> = {
  [TicketGroup.PUBLIC_TRANSPORT_PASSES]: [
    TicketType.SWISS_TRAVEL_PASS,
    TicketType.SWISS_TRAVEL_PASS_FLEX,
    TicketType.SWISS_HALF_FARE_CARD,
  ],
  [TicketGroup.MOUNTAIN_EXCURSION]: [
    TicketType.EXCURSION_JUNGFRAUJOCH,
    TicketType.EXCURSION_TITLIS,
    TicketType.EXCURSION_PILATUS,
    TicketType.EXCURSION_GORNERGRAT,
  ],
  [TicketGroup.REGIONAL_PASSES]: [
    TicketType.REGIONAL_CENTRAL_PASS,
    TicketType.REGIONAL_OBERLAND_PASS,
    TicketType.REGIONAL_JUNGFRAU_TRAVEL_PASS,
  ],
};
