import { createSelector } from 'reselect';
import { getBasketData } from 'src/selectors/getBasketData';
import { ProductCode } from 'src/types/ProductCode';

export const getBasketUnavailableProducts = createSelector([
  getBasketData,
], (data): ReadonlySet<ProductCode> => {
  const result = new Set<ProductCode>();

  for (const product of data.products) {
    if (data.minimalStartDate[product.code]?.startDate == null) {
      result.add(product.code);
    }
  }

  return result;
});
