import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { Language } from 'src/types/Language';
import { P2pTicketLanguage } from 'src/types/P2pTicketLanguage';

export const P2P_TICKET_TRIP_LIST_SIZE = 6;

export const P2P_TICKET_LANGUAGE_MAP: ReadonlyRecord<Language, P2pTicketLanguage> = {
  'en': 'en',
  'de': 'de',
  'it': 'it',
  'es': 'en',
  'fr': 'fr',
  'nl': 'en',
  'ja': 'en',
  'ko': 'en',
  'zh-Hans': 'en',
  'zh-Hant': 'en',
  'pt': 'en',
  'th': 'en',
};
